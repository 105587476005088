
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Peta Zona");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllAdvocacyDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const icon = path + "other/png/advokasii-4.png";

    const markers = computed(() => store.state.AdvocacyDashboardModule.all);
    // kalau di group butuh param icon mark

    const info = ref([
      {
        img: "other/png/advokasii-4.png",
        text: "Advokasi Aleg A",
      },
      {
        img: "other/png/advokasii-5.png",
        text: "Advokasi Aleg B",
      },
      {
        img: "other/png/advokasii-3.png",
        text: "Advokasi Aleg C",
      },
    ]);

    return {
      center,
      markers,
      info,
      zoom,
      icon,
      iconSize,
    };
  },
});
